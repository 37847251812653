export const environment = {
  production: true,
  graphQL: 'https://api.yeranee.com/graphql',
  ws: 'wss://api.yeranee.com/graphql',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZ29yLWp1bGhha3lhbiIsImEiOiJjbDJrYzNmeWQwMnRkM2twYzBqYTc4OXd1In0.bjOXq2UjOrSxPn2z42v10A'
  },
  idram: {
    id: '110001508',
    secret: 'iRdLWQc60HvUStHMBXak6b2jkI9AjLdsUvvzWP'
  },
  ga: 'G-9X5N2V0NPH',
  version: {
    major: 1,
    minor: 43,
    patch: 2
  }
};
